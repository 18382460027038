import { Label } from "./label";
import Select, {
  InputActionMeta,
  OptionsOrGroups,
  GroupBase,
} from "react-select";
import { defaultCustomStyles } from "../styles/creatable-select";
import { Option } from "../types/reactSelectOption";
import { useEffect, useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

type FormSearchableFieldProps<FormType extends FieldValues> = {
  label: string;
  name: string;
  placeholder: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: OptionsOrGroups<any, GroupBase<Option>> | undefined;
  menuPortalTarget?: HTMLElement | null | undefined;
  isClearable?: boolean;
  backspaceRemovesValue?: boolean;
  className?: string;
  classNamePrefix?: string;
  optionSelectionRequired?: boolean;
} & UseControllerProps<FormType>;

export const FormSearchableField = <FormType extends FieldValues>({
  label,
  placeholder,
  value,
  options,
  menuPortalTarget,
  isClearable = true,
  backspaceRemovesValue = true,
  className,
  classNamePrefix = "react-select",
  optionSelectionRequired = false,
  name,
  control,
  rules,
}: FormSearchableFieldProps<FormType>) => {
  const [openMenu, setOpenmenu] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  const onInputChangeHandler = (value: string, eventInfo: InputActionMeta) => {
    if (eventInfo.action === "input-change") {
      setInput(value);
      setSelectedOption(null);
      setOpenmenu(value.length >= 3);
      field.onChange(optionSelectionRequired ? "" : value, name);
    }
    if (eventInfo.action === "menu-close") {
      setOpenmenu(false);
    }
  };

  const onChangeHandler = (value: Option) => {
    setInput("");
    setSelectedOption(value);
    field.onChange(value?.value, name);
  };

  useEffect(() => {
    if (value) {
      setSelectedOption(options?.find((el) => el.value === value));
    }
  }, [options, value]);

  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <Select
        {...field}
        id={name}
        name={name}
        styles={defaultCustomStyles}
        placeholder={placeholder}
        noOptionsMessage={() => "No suggestions"}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        value={selectedOption}
        onChange={(value) => {
          onChangeHandler(value);
        }}
        isSearchable
        inputValue={input}
        onInputChange={(value, eventInfo) => {
          onInputChangeHandler(value, eventInfo);
        }}
        menuIsOpen={openMenu}
        options={options}
        menuPortalTarget={menuPortalTarget}
        className={className}
        classNamePrefix={classNamePrefix}
      />
      <p className="mb-0 text-red text-base fixed">
        {fieldState.error?.message}
      </p>
    </div>
  );
};
