import { useState } from "react";
import { useNavigate } from "react-router";
import { PageTemplateClient } from "../../components/page-template-client";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";
import {
  ClientCheckinNewRequest,
  Organizer,
  useClientCheckinNew,
} from "../../api/__generated__";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { BaseCheckInForm } from "../../types/baseCheckInForm";
import { actions } from "../../types/actions";
import { Input } from "../../components/input";
import { CameraCheckInForm } from "../../types/cameraCheckInForm";
import { validationRules } from "../../util/form-validation";
import { FormSearchableField } from "../../components/form-searchable-field";

export const NewFaceForm = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ClientCheckinNewRequest | null>(
    null
  );
  const {
    email: emailValiationRules,
    nameManualInput: nameManualInputRules,
    appointmentWith: appointmentWithRules,
  } = validationRules;
  const { handleSubmit, control } = useForm<CameraCheckInForm>({
    defaultValues: {
      name: "",
      organization: "",
      email: "",
      appointmentWithId: "",
    },
  });

  const submitForm: SubmitHandler<BaseCheckInForm> = (formData) => {
    if (!state.clientSession || !formData) return;

    const enrichedFormData = {
      ...formData,
      location: state.clientSession?.location || "",
      imageData: state.capturedImage?.image,
    };

    setFormData(enrichedFormData);
    checkinNew.mutate(enrichedFormData);
  };

  const checkinNew = useClientCheckinNew({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_CLIENT_CHECKIN_NEW,
          payload: formData,
        });
        navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=true" });
      },
      onError: (error) => {
        console.error("Error during check in:", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message:
              "Something went wrong during check in, please try again later.",
          },
        });
      },
    },
  });

  return (
    <PageTemplateClient navigateToHome={true}>
      <article>
        <h1 data-testid="page-title" className="mb-2 text-4xl">
          Please fill in your information
        </h1>
        <p>
          Please note: Next time you will be able to check in and out without
          entering your name or e-mail.
        </p>
      </article>
      <form
        className="w-[55vw] overflow-hidden"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="flex flex-col gap-12 mb-14">
          <FormSearchableField<CameraCheckInForm>
            label={"Full name *"}
            name={"name"}
            placeholder={"Full name"}
            rules={nameManualInputRules}
            control={control}
          />
          <Input<CameraCheckInForm>
            label="Organization (optional)"
            control={control}
            inputClassName="pl-2"
            placeholder="Organization"
            name="organization"
          />
          <Input<CameraCheckInForm>
            label="E-mail *"
            control={control}
            placeholder="E-mail"
            name="email"
            inputClassName="pl-2"
            rules={emailValiationRules}
          />
          <FormSearchableField<CameraCheckInForm>
            label={"Appointment with *"}
            name={"appointmentWithId"}
            placeholder={"Select an organizer"}
            rules={appointmentWithRules}
            control={control}
            options={state.organizers?.map((org: Organizer) => ({
              value: org.id,
              label: org.name,
            }))}
            menuPortalTarget={document.body}
            optionSelectionRequired={true}
          />
        </div>
        <Button
          variant={"primary"}
          data-testid="btn-check-in-out"
          isLoading={checkinNew.isPending}
        >
          Check in
        </Button>
      </form>
    </PageTemplateClient>
  );
};
