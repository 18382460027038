import { useNavigate } from "react-router";
import { ReactComponent as IconCheck } from "../svg/check.svg";
import { Button } from "./button";
import { pages } from "../util/pages";

export const CameraConsentText = () => {
  const navigate = useNavigate();

  return (
    <>
      <ul className={"flex flex-col gap-4 text-2xl font-medium"}>
        <li className={"flex items-center gap-2"}>
          <IconCheck className={"fill-green w-12 h-12"} />
          Faster checking in and out
        </li>
        <li className={"flex items-center gap-2"}>
          <IconCheck className={"fill-green w-12 h-12"} />
          We respect your privacy
        </li>
        <li className={"flex items-center gap-2"}>
          <IconCheck className={"fill-green w-12 h-12"} />
          We don't store any images
        </li>
        <li className={"flex items-center gap-2"}>
          <IconCheck className={"fill-green w-12 h-12"} />
          Secure and safe
        </li>
      </ul>
      <p className={"text-xl"}>
        By taking a picture you agree that we process your facial information
        and e-mail in order to make checking in and out easier for you in the
        future. Read our{" "}
        <Button
          variant={"link"}
          className="underline"
          onPointerDown={() => navigate(pages.PRIVACY_POLICY)}
        >
          privacy&nbsp;policy
        </Button>{" "}
        for more details.
      </p>
    </>
  );
};
