import { useEffect } from "react";
import { useGetVatManagementClientsLocations } from "../api/__generated__";
import { useAppData } from "./use-app-data";
import { actions } from "../types/actions";

export const useLocations = () => {
  const { data, error } = useGetVatManagementClientsLocations({
    query: {
      staleTime: 600000, // Override to 10 minutes
    },
  });

  const { dispatch } = useAppData();

  useEffect(() => {
    if (error) {
      console.error(error);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Could not retrieve locations.",
        },
      });
    }
  }, [dispatch, error]);

  return { data };
};
