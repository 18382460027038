import React from "react";
import { twMerge } from "tailwind-merge";

type BadgeProps = {
  children: React.ReactNode;
  variant: "error" | "success" | "info";
  className?: string;
};

export const Badge = ({
  children,
  variant,
  className,
  ...props
}: BadgeProps) => {
  if (!variant) throw new Error("Variant prop for Badge is required.");
  let color = "";

  switch (variant) {
    case "success":
      color = "bg-green-light text-green-dark";
      break;
    case "error":
      color = "bg-red-light text-red-dark";
      break;
    case "info":
      color = "bg-yellow-light text-yellow-dark";
      break;
    default:
      break;
  }

  return (
    <div
      className={twMerge(
        `px-2 py-1 rounded-md text-center ${color}`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
