import { useNavigate } from "react-router";
import { useAppData } from "../hooks/use-app-data";
import { ReactComponent as ChevronLeft } from "../svg/chevron_left.svg";
import { Button } from "../components/button";
import { pages } from "../util/pages";
import { actions } from "../types/actions";

export const NavigateToHome = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppData();

  const navigateToHome = () => {
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.HOME);
  };

  return (
    <div className="flex items-center gap-4 w-full">
      <ChevronLeft />
      <Button
        variant="link"
        onPointerDown={navigateToHome}
        data-testid="btn-back-to-start"
      >
        Go back
      </Button>
    </div>
  );
};
