import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ManagementCheckoutMutationRequest, ManagementCheckoutMutationResponse } from "../types/ManagementCheckout";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ManagementCheckoutClient = typeof client<ManagementCheckoutMutationResponse, never, ManagementCheckoutMutationRequest>;
type ManagementCheckout = {
    data: ManagementCheckoutMutationResponse;
    error: never;
    request: ManagementCheckoutMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ManagementCheckoutMutationResponse;
    client: {
        parameters: Partial<Parameters<ManagementCheckoutClient>[0]>;
        return: Awaited<ReturnType<ManagementCheckoutClient>>;
    };
};
/**
 * @description Check out a checked in visitor. This visit can be retieved using the vat-management/visits call
 * @link /vat-management/checkout
 */
export function useManagementCheckout(options: {
    mutation?: UseMutationOptions<ManagementCheckout["response"], ManagementCheckout["error"], ManagementCheckout["request"]>;
    client?: ManagementCheckout["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ManagementCheckout["data"], ManagementCheckout["error"], ManagementCheckout["request"]>({
                method: "post",
                url: `/vat-management/checkout`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}