import { useCallback } from "react";
import { useAppData } from "../hooks/use-app-data";
import { ReactComponent as CloseIcon } from "../svg/close_icon.svg";
import { actions } from "../types/actions";
import { ProgressBar } from "./progress-bar";

type ToastProps = {
  message: string | null;
};

export const Toast = ({ message }: ToastProps) => {
  const { dispatch } = useAppData();

  const clearNotification = useCallback(() => {
    dispatch({ type: actions.SET_TOAST, payload: null });
  }, [dispatch]);

  if (!message) return null;

  return (
    <div className="fixed bottom-4 left-4 max-w-xs w-full bg-green-light text-green-dark rounded-lg shadow-lg p-4 flex items-center">
      <div className="flex-1">{message}</div>
      <button
        onPointerDown={clearNotification}
        className="ml-4 text-gray-400 hover:text-gray-200 focus:outline-none"
      >
        <CloseIcon className="fill-green-dark" />
      </button>
      <ProgressBar
        onFinished={clearNotification}
        durationSeconds={10}
        className="absolute bg-green-dark"
      />
    </div>
  );
};
