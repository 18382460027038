import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ManagementGenerateClientUrlMutationRequest, ManagementGenerateClientUrlMutationResponse } from "../types/ManagementGenerateClientUrl";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ManagementGenerateClientUrlClient = typeof client<ManagementGenerateClientUrlMutationResponse, never, ManagementGenerateClientUrlMutationRequest>;
type ManagementGenerateClientUrl = {
    data: ManagementGenerateClientUrlMutationResponse;
    error: never;
    request: ManagementGenerateClientUrlMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ManagementGenerateClientUrlMutationResponse;
    client: {
        parameters: Partial<Parameters<ManagementGenerateClientUrlClient>[0]>;
        return: Awaited<ReturnType<ManagementGenerateClientUrlClient>>;
    };
};
/**
 * @description Registers a client with request details
 * @link /vat-management/clients/create
 */
export function useManagementGenerateClientUrl(options: {
    mutation?: UseMutationOptions<ManagementGenerateClientUrl["response"], ManagementGenerateClientUrl["error"], ManagementGenerateClientUrl["request"]>;
    client?: ManagementGenerateClientUrl["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ManagementGenerateClientUrl["data"], ManagementGenerateClientUrl["error"], ManagementGenerateClientUrl["request"]>({
                method: "post",
                url: `/vat-management/clients/create`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}