import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ClientCheckoutMutationRequest, ClientCheckoutMutationResponse } from "../types/ClientCheckout";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ClientCheckoutClient = typeof client<ClientCheckoutMutationResponse, never, ClientCheckoutMutationRequest>;
type ClientCheckout = {
    data: ClientCheckoutMutationResponse;
    error: never;
    request: ClientCheckoutMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ClientCheckoutMutationResponse;
    client: {
        parameters: Partial<Parameters<ClientCheckoutClient>[0]>;
        return: Awaited<ReturnType<ClientCheckoutClient>>;
    };
};
/**
 * @description Check out a checked in visitor. A list of possible visits can be retrieved using the expected-checkouts call..
 * @link /vat-client/checkout
 */
export function useClientCheckout(options: {
    mutation?: UseMutationOptions<ClientCheckout["response"], ClientCheckout["error"], ClientCheckout["request"]>;
    client?: ClientCheckout["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ClientCheckout["data"], ClientCheckout["error"], ClientCheckout["request"]>({
                method: "post",
                url: `/vat-client/checkout`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}