import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

type TimeElementProps<FormType extends FieldValues> = {
  name: string;
  min?: string;
  max?: string;
  required?: boolean;
  className?: string;
} & UseControllerProps<FormType>;

export const TimeElement = <FormType extends FieldValues>({
  required = false,
  className,
  name,
  control,
  rules,
}: TimeElementProps<FormType>) => {
  const { field } = useController({
    name,
    control,
    rules,
  });
  return (
    <input
      className={twMerge(
        `small-text py-1 px-2 rounded-lg border-2 border-grey-grey30`,
        className
      )}
      type="time"
      id={name}
      name={name}
      value={field.value}
      required={required}
      onChange={(event) => {
        field.onChange(event.target.value, name);
      }}
    />
  );
};
