import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Button } from "../components/button";
import { CurrentRegisteredDevice } from "../components/current-registered-device";
import { PageTemplateClient } from "../components/page-template-client";
import { actions } from "../types/actions";
import { useAppData } from "../hooks/use-app-data";
import { pages } from "../util/pages";
import "../styles/index.css";
import { useRetrieveOrganizers } from "../api/__generated__/hooks/";
import { ReactComponent as Camera } from "../svg/camera.svg";

export const Home = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const { data: organizers, isError, error } = useRetrieveOrganizers();

  useEffect(() => {
    // We collect iO organizers here, so we can use it when the confirmation page is shown.
    if (organizers) {
      dispatch({
        type: actions.SET_IO_ORGANIZERS,
        payload: organizers,
      });
    } else if (isError) {
      console.error("Error fetching organizers", error);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Current organizers could not be retrieved",
        },
      });
    }
  }, [dispatch, error, isError, organizers]);

  useEffect(() => {
    // Clear captured image as a flow has been completed or this is first load
    if (state.capturedImage) {
      dispatch({
        type: actions.SET_CAPTURED_IMAGE,
        payload: null,
      });
    }
    // Only trigger once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePageHandler = (page: string) => {
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(page);
  };

  const photoCheckInOut = () => {
    if (!navigator.mediaDevices) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Face recognition requires https",
        },
      });
      return;
    }
    changePageHandler(pages.RECOGNIZE);
  };

  const HomeFooter = () => (
    <>
      <p>
        By tapping check in/out, you agree to our{" "}
        <Button
          className="underline"
          variant={"link"}
          onPointerDown={() => changePageHandler(pages.PRIVACY_POLICY)}
        >
          privacy&nbsp;policy
        </Button>
      </p>
      <CurrentRegisteredDevice />
    </>
  );

  return (
    <PageTemplateClient footer={<HomeFooter />}>
      <h1 className={"text-grey-grey90"}>Hello,</h1>
      <h1 className={"mb-24 text-grey-grey90"}>Welcome to iO!</h1>

      <div className={"flex w-[55vw] items-start flex-col gap-8"}>
        <Button
          variant={"secondary"}
          onPointerDown={() => changePageHandler(pages.CHECK_IN)}
          data-testid={"btn-face-recognition"}
        >
          Check in
        </Button>
        <Button
          variant={"secondary"}
          onPointerDown={() => changePageHandler(pages.CHECK_OUT)}
          data-testid={"btn-face-recognition"}
        >
          Check out
        </Button>
        <Button
          variant={"primary"}
          onPointerDown={photoCheckInOut}
          data-testid={"btn-manual-form"}
        >
          <Camera className="inline-flex mr-2" />
          Instant photo check in/out
        </Button>
      </div>
    </PageTemplateClient>
  );
};
