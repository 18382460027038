import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetUsersManagementQueryResponse, GetUsersManagementQueryParams } from "../types/GetUsersManagement";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetUsersManagementClient = typeof client<GetUsersManagementQueryResponse, never, never>;
type GetUsersManagement = {
    data: GetUsersManagementQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: GetUsersManagementQueryParams;
    headerParams: never;
    response: GetUsersManagementQueryResponse;
    client: {
        parameters: Partial<Parameters<GetUsersManagementClient>[0]>;
        return: Awaited<ReturnType<GetUsersManagementClient>>;
    };
};
export const getUsersManagementQueryKey = (params?: GetUsersManagement["queryParams"]) => [{ url: "/vat-management/users" }, ...(params ? [params] : [])] as const;
export type GetUsersManagementQueryKey = ReturnType<typeof getUsersManagementQueryKey>;
export function getUsersManagementQueryOptions(params?: GetUsersManagement["queryParams"], options: GetUsersManagement["client"]["parameters"] = {}) {
    const queryKey = getUsersManagementQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetUsersManagement["data"], GetUsersManagement["error"]>({
                method: "get",
                url: `/vat-management/users`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get all users or get users matching search param
 * @link /vat-management/users
 */
export function useGetUsersManagement<TData = GetUsersManagement["response"], TQueryData = GetUsersManagement["response"], TQueryKey extends QueryKey = GetUsersManagementQueryKey>(params?: GetUsersManagement["queryParams"], options: {
    query?: Partial<QueryObserverOptions<GetUsersManagement["response"], GetUsersManagement["error"], TData, TQueryData, TQueryKey>>;
    client?: GetUsersManagement["client"]["parameters"];
} = {}): UseQueryResult<TData, GetUsersManagement["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getUsersManagementQueryKey(params);
    const query = useQuery({
        ...getUsersManagementQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetUsersManagement["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getUsersManagementSuspenseQueryKey = (params?: GetUsersManagement["queryParams"]) => [{ url: "/vat-management/users" }, ...(params ? [params] : [])] as const;
export type GetUsersManagementSuspenseQueryKey = ReturnType<typeof getUsersManagementSuspenseQueryKey>;
export function getUsersManagementSuspenseQueryOptions(params?: GetUsersManagement["queryParams"], options: GetUsersManagement["client"]["parameters"] = {}) {
    const queryKey = getUsersManagementSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetUsersManagement["data"], GetUsersManagement["error"]>({
                method: "get",
                url: `/vat-management/users`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get all users or get users matching search param
 * @link /vat-management/users
 */
export function useGetUsersManagementSuspense<TData = GetUsersManagement["response"], TQueryKey extends QueryKey = GetUsersManagementSuspenseQueryKey>(params?: GetUsersManagement["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<GetUsersManagement["response"], GetUsersManagement["error"], TData, TQueryKey>>;
    client?: GetUsersManagement["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetUsersManagement["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getUsersManagementSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...getUsersManagementSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetUsersManagement["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}