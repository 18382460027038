import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ManagementDeleteClientMutationResponse, ManagementDeleteClientPathParams } from "../types/ManagementDeleteClient";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ManagementDeleteClientClient = typeof client<ManagementDeleteClientMutationResponse, never, never>;
type ManagementDeleteClient = {
    data: ManagementDeleteClientMutationResponse;
    error: never;
    request: never;
    pathParams: ManagementDeleteClientPathParams;
    queryParams: never;
    headerParams: never;
    response: ManagementDeleteClientMutationResponse;
    client: {
        parameters: Partial<Parameters<ManagementDeleteClientClient>[0]>;
        return: Awaited<ReturnType<ManagementDeleteClientClient>>;
    };
};
/**
 * @description Deletes a client by session id, therefore revoking the session
 * @link /vat-management/clients/:sessionId
 */
export function useManagementDeleteClient(sessionId: ManagementDeleteClientPathParams["sessionId"], options: {
    mutation?: UseMutationOptions<ManagementDeleteClient["response"], ManagementDeleteClient["error"], ManagementDeleteClient["request"]>;
    client?: ManagementDeleteClient["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async () => {
            const res = await client<ManagementDeleteClient["data"], ManagementDeleteClient["error"], ManagementDeleteClient["request"]>({
                method: "delete",
                url: `/vat-management/clients/${sessionId}`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}