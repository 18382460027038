import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ManagementRetrieveOrganizersQueryResponse } from "../types/ManagementRetrieveOrganizers";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ManagementRetrieveOrganizersClient = typeof client<ManagementRetrieveOrganizersQueryResponse, never, never>;
type ManagementRetrieveOrganizers = {
    data: ManagementRetrieveOrganizersQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ManagementRetrieveOrganizersQueryResponse;
    client: {
        parameters: Partial<Parameters<ManagementRetrieveOrganizersClient>[0]>;
        return: Awaited<ReturnType<ManagementRetrieveOrganizersClient>>;
    };
};
export const managementRetrieveOrganizersQueryKey = () => [{ url: "/vat-management/organizers" }] as const;
export type ManagementRetrieveOrganizersQueryKey = ReturnType<typeof managementRetrieveOrganizersQueryKey>;
export function managementRetrieveOrganizersQueryOptions(options: ManagementRetrieveOrganizers["client"]["parameters"] = {}) {
    const queryKey = managementRetrieveOrganizersQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ManagementRetrieveOrganizers["data"], ManagementRetrieveOrganizers["error"]>({
                method: "get",
                url: `/vat-management/organizers`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Retrieve all possible meeting organizers (Usually the employees of the company)
 * @link /vat-management/organizers
 */
export function useManagementRetrieveOrganizers<TData = ManagementRetrieveOrganizers["response"], TQueryData = ManagementRetrieveOrganizers["response"], TQueryKey extends QueryKey = ManagementRetrieveOrganizersQueryKey>(options: {
    query?: Partial<QueryObserverOptions<ManagementRetrieveOrganizers["response"], ManagementRetrieveOrganizers["error"], TData, TQueryData, TQueryKey>>;
    client?: ManagementRetrieveOrganizers["client"]["parameters"];
} = {}): UseQueryResult<TData, ManagementRetrieveOrganizers["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? managementRetrieveOrganizersQueryKey();
    const query = useQuery({
        ...managementRetrieveOrganizersQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ManagementRetrieveOrganizers["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const managementRetrieveOrganizersSuspenseQueryKey = () => [{ url: "/vat-management/organizers" }] as const;
export type ManagementRetrieveOrganizersSuspenseQueryKey = ReturnType<typeof managementRetrieveOrganizersSuspenseQueryKey>;
export function managementRetrieveOrganizersSuspenseQueryOptions(options: ManagementRetrieveOrganizers["client"]["parameters"] = {}) {
    const queryKey = managementRetrieveOrganizersSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ManagementRetrieveOrganizers["data"], ManagementRetrieveOrganizers["error"]>({
                method: "get",
                url: `/vat-management/organizers`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Retrieve all possible meeting organizers (Usually the employees of the company)
 * @link /vat-management/organizers
 */
export function useManagementRetrieveOrganizersSuspense<TData = ManagementRetrieveOrganizers["response"], TQueryKey extends QueryKey = ManagementRetrieveOrganizersSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<ManagementRetrieveOrganizers["response"], ManagementRetrieveOrganizers["error"], TData, TQueryKey>>;
    client?: ManagementRetrieveOrganizers["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ManagementRetrieveOrganizers["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? managementRetrieveOrganizersSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...managementRetrieveOrganizersSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ManagementRetrieveOrganizers["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}