import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

type ModalProps = {
  className?: string;
  show: boolean;
  tapOutsideShouldClose?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isManagementModal?: boolean;
};

export const Modal = ({
  className,
  show,
  tapOutsideShouldClose = true,
  onClose,
  children,
  isManagementModal = false,
}: ModalProps) => {
  const modal = useRef(null);
  const showModalClass = show ? "flex" : "hidden";
  const modalCss = isManagementModal ? "bg-white p-6 max-w-2xl min-w-120" : "";

  const onKeyDown = (event: { key: string }) => {
    if (event.key === "Escape") {
      onClose && onClose();
    }
  };

  const onPointerDown = (event: Event) => {
    if (event.target === modal.current && tapOutsideShouldClose) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("pointerdown", onPointerDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("pointerdown", onPointerDown);
    };
  });

  return (
    <div
      id="modal"
      className={twMerge(
        "fixed inset-0 overflow-auto bg-black/40",
        showModalClass
      )}
      ref={modal}
    >
      <div
        className={twMerge(
          `relative m-auto rounded-md bg-primary-baseBlue p-10 ${modalCss}`,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
