import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";
import { NavigateToHome } from "../../components/navigate-to-home";

export const NotDetected = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();

  const tryAgainHandler = () => {
    dispatch({
      type: actions.SET_CAPTURED_IMAGE,
      payload: null,
    });
    navigate({
      pathname: pages.RECOGNIZE,
      search: "?tips=true",
    });
  };

  return (
    <PageTemplateClient footer={<NavigateToHome />}>
      <h1>Face not detected 🧐</h1>
      <p>We couldn't detect a face in the picture, could you try again?</p>
      {state.capturedImage && (
        <img
          className="border-black mt-8 border-2 rounded-full w-64 h-64 mx-auto object-cover -scale-x-100"
          src={state.capturedImage.imageWithMeta}
          alt="You"
        />
      )}
      <div className={"items-center"}>
        <Button variant={"primary"} onPointerDown={tryAgainHandler}>
          Try again
        </Button>
      </div>
    </PageTemplateClient>
  );
};
