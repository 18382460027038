import { StateType } from "../types/stateType";

export const actions = {
  SET_RECOGNIZED_VISITOR: "SET_RECOGNIZED_VISITOR",
  SET_CLIENT_CHECKIN_NEW: "SET_CLIENT_CHECKIN_NEW",
  SET_CHECKED_OUT_VISITOR: "SET_CHECKED_OUT_VISITOR",
  SET_ERROR: "SET_ERROR",
  SET_CAPTURED_IMAGE: "SET_CAPTURED_IMAGE",
  TOGGLE_FETCH_CURRENT_VISITORS: "TOGGLE_FETCH_CURRENT_VISITORS",
  SET_USERS: "SET_USERS",
  SET_CLIENTS: "SET_CLIENTS",
  SET_ROLES: "SET_ROLES",
  SET_PROFILE: "SET_PROFILE",
  SET_IO_ORGANIZERS: "SET_IO_ORGANIZERS",
  SET_QR_DATA: "SET_QR_DATA",
  SET_CLIENT_SESSION: "SET_CLIENT_SESSION",
  SET_EXPECTED_CHECKOUTS: "SET_EXPECTED_CHECKOUTS",
  SET_DATE: "SET_DATE",
  SET_TOAST: "SET_TOAST",
} as const;

// Defining each type based on the stateType
type RecognizedVisitorPayload = StateType["recognizedVisitor"];
type ClientCheckInNewPayload = StateType["clientCheckInNew"];
type ErrorPayload = StateType["error"];
type CapturedImagePayload = StateType["capturedImage"];
type ToggleFetchCurrentVisitorsPayload =
  StateType["toggleFetchCurrentVisitors"];
type ClientsPayload = StateType["clients"];
type UsersPayload = StateType["users"];
type RolesPayload = StateType["roles"];
type ProfilePayload = StateType["profile"];
type OrganizersPayload = StateType["organizers"];
type QRDataPayload = StateType["qrData"];
type ClientSessionPayload = StateType["clientSession"];
type ExpectedCheckoutsPayload = StateType["expectedCheckouts"];
type CheckedOutVisitorPayload = StateType["checkedOutVisitor"];
type DatePayload = StateType["date"];
type ToastPayload = StateType["toast"];

interface RecognizedVisitorAction {
  type: typeof actions.SET_RECOGNIZED_VISITOR;
  payload: RecognizedVisitorPayload;
}

interface ClientCheckInNewAction {
  type: typeof actions.SET_CLIENT_CHECKIN_NEW;
  payload: ClientCheckInNewPayload;
}

interface CheckedOutVisitorAction {
  type: typeof actions.SET_CHECKED_OUT_VISITOR;
  payload: CheckedOutVisitorPayload;
}

interface ErrorAction {
  type: typeof actions.SET_ERROR;
  payload: ErrorPayload;
}

interface CapturedImageAction {
  type: typeof actions.SET_CAPTURED_IMAGE;
  payload: CapturedImagePayload;
}

interface ToggleFetchCurrentVisitorsAction {
  type: typeof actions.TOGGLE_FETCH_CURRENT_VISITORS;
  payload: ToggleFetchCurrentVisitorsPayload;
}

interface ClientsAction {
  type: typeof actions.SET_CLIENTS;
  payload: ClientsPayload;
}

interface UsersAction {
  type: typeof actions.SET_USERS;
  payload: UsersPayload;
}

interface RolesAction {
  type: typeof actions.SET_ROLES;
  payload: RolesPayload;
}

interface ProfileAction {
  type: typeof actions.SET_PROFILE;
  payload: ProfilePayload;
}

interface OrganizersAction {
  type: typeof actions.SET_IO_ORGANIZERS;
  payload: OrganizersPayload;
}

interface QRDataAction {
  type: typeof actions.SET_QR_DATA;
  payload: QRDataPayload;
}

interface ClientSessionAction {
  type: typeof actions.SET_CLIENT_SESSION;
  payload: ClientSessionPayload;
}

interface ExpectedCheckoutsAction {
  type: typeof actions.SET_EXPECTED_CHECKOUTS;
  payload: ExpectedCheckoutsPayload;
}
interface DateAction {
  type: typeof actions.SET_DATE;
  payload: DatePayload;
}

interface ToastAction {
  type: typeof actions.SET_TOAST;
  payload: ToastPayload;
}

export type Action =
  | ExpectedCheckoutsAction
  | RecognizedVisitorAction
  | ClientCheckInNewAction
  | CheckedOutVisitorAction
  | ErrorAction
  | CapturedImageAction
  | ToggleFetchCurrentVisitorsAction
  | ClientsAction
  | UsersAction
  | RolesAction
  | ProfileAction
  | OrganizersAction
  | QRDataAction
  | ClientSessionAction
  | DateAction
  | ToastAction;
