import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ManagementCreateVisitMutationRequest, ManagementCreateVisitMutationResponse } from "../types/ManagementCreateVisit";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ManagementCreateVisitClient = typeof client<ManagementCreateVisitMutationResponse, never, ManagementCreateVisitMutationRequest>;
type ManagementCreateVisit = {
    data: ManagementCreateVisitMutationResponse;
    error: never;
    request: ManagementCreateVisitMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ManagementCreateVisitMutationResponse;
    client: {
        parameters: Partial<Parameters<ManagementCreateVisitClient>[0]>;
        return: Awaited<ReturnType<ManagementCreateVisitClient>>;
    };
};
/**
 * @description Create a visit. All visit and visitor data needs to be supplied.
 * @link /vat-management/visits
 */
export function useManagementCreateVisit(options: {
    mutation?: UseMutationOptions<ManagementCreateVisit["response"], ManagementCreateVisit["error"], ManagementCreateVisit["request"]>;
    client?: ManagementCreateVisit["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ManagementCreateVisit["data"], ManagementCreateVisit["error"], ManagementCreateVisit["request"]>({
                method: "post",
                url: `/vat-management/visits`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}