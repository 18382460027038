import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetAllRolesQueryResponse } from "../types/GetAllRoles";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetAllRolesClient = typeof client<GetAllRolesQueryResponse, never, never>;
type GetAllRoles = {
    data: GetAllRolesQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: GetAllRolesQueryResponse;
    client: {
        parameters: Partial<Parameters<GetAllRolesClient>[0]>;
        return: Awaited<ReturnType<GetAllRolesClient>>;
    };
};
export const getAllRolesQueryKey = () => [{ url: "/vat-management/users/roles" }] as const;
export type GetAllRolesQueryKey = ReturnType<typeof getAllRolesQueryKey>;
export function getAllRolesQueryOptions(options: GetAllRoles["client"]["parameters"] = {}) {
    const queryKey = getAllRolesQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetAllRoles["data"], GetAllRoles["error"]>({
                method: "get",
                url: `/vat-management/users/roles`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get all roles
 * @link /vat-management/users/roles
 */
export function useGetAllRoles<TData = GetAllRoles["response"], TQueryData = GetAllRoles["response"], TQueryKey extends QueryKey = GetAllRolesQueryKey>(options: {
    query?: Partial<QueryObserverOptions<GetAllRoles["response"], GetAllRoles["error"], TData, TQueryData, TQueryKey>>;
    client?: GetAllRoles["client"]["parameters"];
} = {}): UseQueryResult<TData, GetAllRoles["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getAllRolesQueryKey();
    const query = useQuery({
        ...getAllRolesQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetAllRoles["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getAllRolesSuspenseQueryKey = () => [{ url: "/vat-management/users/roles" }] as const;
export type GetAllRolesSuspenseQueryKey = ReturnType<typeof getAllRolesSuspenseQueryKey>;
export function getAllRolesSuspenseQueryOptions(options: GetAllRoles["client"]["parameters"] = {}) {
    const queryKey = getAllRolesSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetAllRoles["data"], GetAllRoles["error"]>({
                method: "get",
                url: `/vat-management/users/roles`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get all roles
 * @link /vat-management/users/roles
 */
export function useGetAllRolesSuspense<TData = GetAllRoles["response"], TQueryKey extends QueryKey = GetAllRolesSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<GetAllRoles["response"], GetAllRoles["error"], TData, TQueryKey>>;
    client?: GetAllRoles["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetAllRoles["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getAllRolesSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...getAllRolesSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetAllRoles["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}