import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ClientExpectedCheckoutsQueryResponse } from "../types/ClientExpectedCheckouts";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ClientExpectedCheckoutsClient = typeof client<ClientExpectedCheckoutsQueryResponse, never, never>;
type ClientExpectedCheckouts = {
    data: ClientExpectedCheckoutsQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ClientExpectedCheckoutsQueryResponse;
    client: {
        parameters: Partial<Parameters<ClientExpectedCheckoutsClient>[0]>;
        return: Awaited<ReturnType<ClientExpectedCheckoutsClient>>;
    };
};
export const clientExpectedCheckoutsQueryKey = () => [{ url: "/vat-client/expected-checkouts" }] as const;
export type ClientExpectedCheckoutsQueryKey = ReturnType<typeof clientExpectedCheckoutsQueryKey>;
export function clientExpectedCheckoutsQueryOptions(options: ClientExpectedCheckouts["client"]["parameters"] = {}) {
    const queryKey = clientExpectedCheckoutsQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ClientExpectedCheckouts["data"], ClientExpectedCheckouts["error"]>({
                method: "get",
                url: `/vat-client/expected-checkouts`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Return a list of visits that are checked in today but not yet checked out
 * @link /vat-client/expected-checkouts
 */
export function useClientExpectedCheckouts<TData = ClientExpectedCheckouts["response"], TQueryData = ClientExpectedCheckouts["response"], TQueryKey extends QueryKey = ClientExpectedCheckoutsQueryKey>(options: {
    query?: Partial<QueryObserverOptions<ClientExpectedCheckouts["response"], ClientExpectedCheckouts["error"], TData, TQueryData, TQueryKey>>;
    client?: ClientExpectedCheckouts["client"]["parameters"];
} = {}): UseQueryResult<TData, ClientExpectedCheckouts["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? clientExpectedCheckoutsQueryKey();
    const query = useQuery({
        ...clientExpectedCheckoutsQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ClientExpectedCheckouts["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const clientExpectedCheckoutsSuspenseQueryKey = () => [{ url: "/vat-client/expected-checkouts" }] as const;
export type ClientExpectedCheckoutsSuspenseQueryKey = ReturnType<typeof clientExpectedCheckoutsSuspenseQueryKey>;
export function clientExpectedCheckoutsSuspenseQueryOptions(options: ClientExpectedCheckouts["client"]["parameters"] = {}) {
    const queryKey = clientExpectedCheckoutsSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ClientExpectedCheckouts["data"], ClientExpectedCheckouts["error"]>({
                method: "get",
                url: `/vat-client/expected-checkouts`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Return a list of visits that are checked in today but not yet checked out
 * @link /vat-client/expected-checkouts
 */
export function useClientExpectedCheckoutsSuspense<TData = ClientExpectedCheckouts["response"], TQueryKey extends QueryKey = ClientExpectedCheckoutsSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<ClientExpectedCheckouts["response"], ClientExpectedCheckouts["error"], TData, TQueryKey>>;
    client?: ClientExpectedCheckouts["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ClientExpectedCheckouts["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? clientExpectedCheckoutsSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...clientExpectedCheckoutsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ClientExpectedCheckouts["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}