import { Option } from "../types/reactSelectOption";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Label } from "./label";
import { SearchableDropdown } from "./searchable-dropdown";

type SelectElementType<FormType extends FieldValues> = {
  label?: string;
  placeholder: string;
  options: Option[] | undefined;
} & UseControllerProps<FormType>;

export const FormSearchableDropdown = <FormType extends FieldValues>({
  label,
  placeholder,
  options,
  name,
  control,
  rules,
}: SelectElementType<FormType>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <SearchableDropdown
        className="mt-2"
        placeholder={placeholder}
        onChange={(value) => field.onChange(value?.value, name)}
        options={options}
      />
      <p className="mb-0 text-red text-base fixed">
        {fieldState.error?.message}
      </p>
    </div>
  );
};
