import { ReactComponent as IconBulb } from "../svg/bulb.svg";

export const CameraTipsText = () => (
  <>
    <p className={"mb-0 text-xl"}>Here are some tips to take a good picture:</p>
    <ul className={"flex flex-col gap-6 text-2xl font-medium"}>
      <li className={"flex items-center gap-2"}>
        <IconBulb className={"fill-[#eab308] w-8 h-8"} />
        Look straight at the camera
      </li>
      <li className={"flex items-center gap-2"}>
        <IconBulb className={"fill-[#eab308] w-8 h-8"} />
        Keep your whole face in frame
      </li>
      <li className={"flex items-center gap-2"}>
        <IconBulb className={"fill-[#eab308] w-8 h-8"} />
        Try not to blink
      </li>
    </ul>
  </>
);
