import { useEffect, useState } from "react";
import { PageTemplateClient } from "../../components/page-template-client";
import { Button } from "../../components/button";
import { useNavigate } from "react-router";
import { pages } from "../../util/pages";
import { useAppData } from "../../hooks/use-app-data";
import { NavigateToHome } from "../../components/navigate-to-home";
import {
  ClientCheckinNewRequest,
  Organizer,
  useClientCheckinNew,
  useClientCheckout,
  Visit,
} from "../../api/__generated__";
import { actions } from "../../types/actions";
import { SubmitHandler, useForm } from "react-hook-form";
import { BaseCheckInForm } from "../../types/baseCheckInForm";
import { FormSearchableField } from "../../components/form-searchable-field";
import { validationRules } from "../../util/form-validation";

export const FaceRecognized = () => {
  const { state, dispatch } = useAppData();
  const navigate = useNavigate();
  const [visitorCheckedIn, setVisitorCheckedIn] = useState<boolean>(false);
  const [retrievedVisit, setRetrievedVisit] = useState<Visit>();
  const [newVisitor, setNewVisitor] = useState<ClientCheckinNewRequest | null>(
    null
  );
  const { appointmentWith: appointmentWithRules } = validationRules;
  const methods = useForm<BaseCheckInForm>();
  const { control } = methods;

  useEffect(() => {
    // Checking if recognized visitor is null
    if (!state.recognizedVisitor) {
      console.error("Error: recognized visitor is null");
      const message = `We couldn't process your action. Please try again.`;
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message,
        },
      });
      navigate(pages.HOME);
      return;
    }
  }, [dispatch, navigate, state.expectedCheckouts, state.recognizedVisitor]);

  useEffect(() => {
    if (!state.expectedCheckouts) return;
    const visit = state.expectedCheckouts.find(
      (visit) => visit.visitor.id === state.recognizedVisitor?.visitor.id
    );
    if (visit) {
      setRetrievedVisit(visit);
      setVisitorCheckedIn(true);
    }
  }, [state.expectedCheckouts, state.recognizedVisitor?.visitor.id]);

  const handleTryAgain = () => {
    navigate({ pathname: pages.RECOGNIZE, search: "?tips=true" });
  };

  const handleCheckOut = () => {
    if (!retrievedVisit || !retrievedVisit.id) return;
    checkOut.mutate({ visitId: retrievedVisit?.id });
    navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=false" });
  };

  const submitForm: SubmitHandler<BaseCheckInForm> = (formData) => {
    if (!state.clientSession || !formData) return;
    const data = {
      ...formData,
      ...state.recognizedVisitor?.visitor,
      imageData: state.capturedImage?.image,
      organization: formData.organization || "",
      location: state.clientSession?.location || "",
    };
    setNewVisitor(data);
    checkinNew.mutate(data);
  };

  const checkOut = useClientCheckout({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_CHECKED_OUT_VISITOR,
          payload: retrievedVisit?.visitor || null,
        });
        navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=false" });
      },
      onError: (error) => onError(error),
    },
  });

  const checkinNew = useClientCheckinNew({
    mutation: {
      onSuccess: () => {
        dispatch({
          type: actions.SET_CLIENT_CHECKIN_NEW,
          payload: newVisitor,
        });
        navigate({ pathname: pages.CONFIRMATION, search: "?checkedIn=true" });
      },
      onError: (error) => onError(error),
    },
  });

  const onError = (error: never) => {
    const action = visitorCheckedIn ? "check out" : "check in";
    console.error(`Error during ${action}:`, error);
    dispatch({
      type: actions.SET_ERROR,
      payload: {
        code: "application_error",
        message: `Something went wrong during ${action}, please try again later.`,
      },
    });
  };

  const CheckInForm = () => {
    return (
      <form
        className="w-[55vw] overflow-hidden "
        onSubmit={methods.handleSubmit(submitForm)}
      >
        <FormSearchableField<BaseCheckInForm>
          label={"Appointment with *"}
          name={"appointmentWithId"}
          placeholder={"Select an organizer"}
          rules={appointmentWithRules}
          control={control}
          options={state.organizers?.map((org: Organizer) => ({
            value: org.id,
            label: org.name,
          }))}
          menuPortalTarget={document.body}
          optionSelectionRequired={true}
        />
        <Button
          variant={"primary"}
          isLoading={checkinNew.isPending}
          className="mt-14"
        >
          Check in
        </Button>
      </form>
    );
  };

  return (
    <PageTemplateClient footer={<NavigateToHome />}>
      <h1 data-testid="greeting">
        Hello {state.recognizedVisitor?.visitor.name} 👋
      </h1>
      {state.capturedImage && (
        <div className="flex flex-row items-center my-12">
          <img
            className="border-black border-2 rounded-full w-40 h-40 object-cover -scale-x-100"
            src={state.capturedImage.imageWithMeta}
            alt="You"
          />
          <div className="flex flex-col ml-10">
            <p>{state.recognizedVisitor?.visitor.name}</p>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-8 w-[55vw]">
        {visitorCheckedIn ? (
          <Button
            variant={"primary"}
            isLoading={checkOut.isPending}
            onPointerDown={handleCheckOut}
          >
            Check out
          </Button>
        ) : (
          <CheckInForm />
        )}
        <Button variant={"secondary"} onPointerDown={handleTryAgain}>
          This is not me, try again
        </Button>
      </div>
    </PageTemplateClient>
  );
};
