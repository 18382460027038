import Select from "react-select";
import { modalCustomStyles } from "../styles/creatable-select";
import { Option } from "../types/reactSelectOption";

type DropdownProps = {
  placeholder: string;
  options: Option[] | undefined;
  defaultValue?: Option;
  className?: string;
  onChange: (event: Option | null) => void;
};

export const SearchableDropdown = ({
  className,
  placeholder,
  options,
  onChange,
  defaultValue,
}: DropdownProps) => {
  return (
    <Select
      className={className}
      defaultValue={defaultValue}
      placeholder={placeholder}
      options={options}
      onChange={(value) => onChange(value)}
      styles={modalCustomStyles}
    />
  );
};
