import { useState, useEffect } from "react";
import { CountdownProps } from "../types/countdownProps";

export function useCountdown({
  onFinished,
  initialTicks = 3,
  tickDuration = 1000,
}: CountdownProps) {
  const [ticks, setTicks] = useState(initialTicks);

  useEffect(() => {
    const id = setInterval(() => {
      setTicks((s) => {
        const val = s - 1;
        if (val === 0) {
          clearInterval(id);
        }
        return val;
      });
    }, tickDuration);

    return () => {
      clearInterval(id);
    };
  }, [onFinished, tickDuration]);

  // Call onFinished inside effect to avoid "cannot update component while
  // rendering a different component"
  useEffect(() => {
    if (ticks === 0) {
      onFinished && onFinished();
    }
  }, [ticks, onFinished]);

  return ticks;
}
