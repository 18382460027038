import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetLoggedInUserQueryResponse } from "../types/GetLoggedInUser";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type GetLoggedInUserClient = typeof client<GetLoggedInUserQueryResponse, never, never>;
type GetLoggedInUser = {
    data: GetLoggedInUserQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: GetLoggedInUserQueryResponse;
    client: {
        parameters: Partial<Parameters<GetLoggedInUserClient>[0]>;
        return: Awaited<ReturnType<GetLoggedInUserClient>>;
    };
};
export const getLoggedInUserQueryKey = () => [{ url: "/vat-management/users/me" }] as const;
export type GetLoggedInUserQueryKey = ReturnType<typeof getLoggedInUserQueryKey>;
export function getLoggedInUserQueryOptions(options: GetLoggedInUser["client"]["parameters"] = {}) {
    const queryKey = getLoggedInUserQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetLoggedInUser["data"], GetLoggedInUser["error"]>({
                method: "get",
                url: `/vat-management/users/me`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Retrieves the userInfo for the user that is logged on, based on the jwt.
 * @link /vat-management/users/me
 */
export function useGetLoggedInUser<TData = GetLoggedInUser["response"], TQueryData = GetLoggedInUser["response"], TQueryKey extends QueryKey = GetLoggedInUserQueryKey>(options: {
    query?: Partial<QueryObserverOptions<GetLoggedInUser["response"], GetLoggedInUser["error"], TData, TQueryData, TQueryKey>>;
    client?: GetLoggedInUser["client"]["parameters"];
} = {}): UseQueryResult<TData, GetLoggedInUser["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getLoggedInUserQueryKey();
    const query = useQuery({
        ...getLoggedInUserQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, GetLoggedInUser["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const getLoggedInUserSuspenseQueryKey = () => [{ url: "/vat-management/users/me" }] as const;
export type GetLoggedInUserSuspenseQueryKey = ReturnType<typeof getLoggedInUserSuspenseQueryKey>;
export function getLoggedInUserSuspenseQueryOptions(options: GetLoggedInUser["client"]["parameters"] = {}) {
    const queryKey = getLoggedInUserSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<GetLoggedInUser["data"], GetLoggedInUser["error"]>({
                method: "get",
                url: `/vat-management/users/me`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Retrieves the userInfo for the user that is logged on, based on the jwt.
 * @link /vat-management/users/me
 */
export function useGetLoggedInUserSuspense<TData = GetLoggedInUser["response"], TQueryKey extends QueryKey = GetLoggedInUserSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<GetLoggedInUser["response"], GetLoggedInUser["error"], TData, TQueryKey>>;
    client?: GetLoggedInUser["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, GetLoggedInUser["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getLoggedInUserSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...getLoggedInUserSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, GetLoggedInUser["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}