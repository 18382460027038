export const pages = {
  // Management
  MANAGEMENT: "/management",
  MANAGEMENT_VISITORS: "/management/visitors",
  MANAGEMENT_USERS: "/management/users",
  MANAGEMENT_DEVICES: "/management/devices",
  MANAGEMENT_DEVICES_REGISTER: "/management/devices/register",
  __LEGACY_MANAGEMENT: "/overview",
  __LEGACY_MANAGEMENT_USERS: "/user-management",
  __LEGACY_MANAGEMENT_DEVICES: "/device-management",

  // Visitor
  HOME: "/",
  RECOGNIZE: "/recognize",
  FACE_NOT_RECOGNIZED: "/recognize/not-recognized",
  FACE_NOT_DETECTED: "/recognize/not-detected",
  CHECK_IN: "/check-in",
  CHECK_OUT: "/check-out",
  CONFIRMATION: "/confirmation",
  PRIVACY_POLICY: "/privacy",
  CLIENT_REGISTER: "/register",
  CLIENT_REGISTER_SCAN: "/register/scan",
  CLIENT_REGISTER_SUCCCESS: "/register/success",
  NEW_FACE_FORM: "/recognize/register-visitor",
  FACE_RECOGNIZED: "/recognize/visitor-recognized",
};
