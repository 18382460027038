import { SearchableDropdown } from "./searchable-dropdown";
import { Option } from "../types/reactSelectOption";
import { GetVatManagementClientsLocationsQueryResponse } from "../api/__generated__";

type LocationFilterProps = {
  clientLocations: GetVatManagementClientsLocationsQueryResponse;
  handleSelectedLocation: (option: Option | null) => void;
  value: string;
};

export const LocationFilter = ({
  clientLocations,
  handleSelectedLocation,
  value,
}: LocationFilterProps) => {
  const convertToReadableLocation = (location: string) => {
    return location
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <SearchableDropdown
      placeholder="Select campus"
      onChange={handleSelectedLocation}
      options={clientLocations.map((location) => ({
        value: location,
        label: convertToReadableLocation(location),
      }))}
      defaultValue={{
        label: convertToReadableLocation(value) || "Select campus",
        value: value,
      }}
    />
  );
};
