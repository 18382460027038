import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Button } from "./button";
import { ButtonVariants } from "../types/buttonVariants";
import { ReactComponent as ChevronDown } from "../svg/chevron_down.svg";
import { ReactComponent as Calendar } from "../svg/calendar.svg";

type DatepickerProps = {
  selectedDate: Date;
  onChange: (date: Date) => void;
  isSmall?: boolean;
  isForm?: boolean;
  maxDate?: Date;
  variant?: ButtonVariants;
};
export const CustomDatePicker = ({
  selectedDate,
  onChange,
  maxDate,
  isSmall = false,
  isForm = false,
  variant = "primary",
}: DatepickerProps) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const buttonStyling = isForm
    ? "w-full bg-white rounded-lg h-9"
    : "w-48 justify-center";

  const dateText = `${selectedDate.toLocaleString("en-GB", {
    month: "long",
  })}
  ${selectedDate.getDate()}`;

  const handleOpenDatePicker = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const CustomInputField = forwardRef<HTMLButtonElement, unknown>(
    (props, ref) => (
      <Button
        isSmall={isSmall}
        variant={variant}
        onPointerDown={handleOpenDatePicker}
        ref={ref}
        className={`${buttonStyling} py-2.5 px-2 flex items-center gap-x-2`}
      >
        <Calendar className={isForm ? "fill-black" : ""} />
        {dateText}
        <ChevronDown className={isForm ? "ml-auto" : "fill-white"} />
      </Button>
    )
  );

  const onDateChange = (date: Date | null) => {
    setDatePickerIsOpen(false);
    if (date) onChange(date);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => onDateChange(date)}
      open={datePickerIsOpen}
      customInput={<CustomInputField />}
      showPopperArrow={false}
      onClickOutside={() => setDatePickerIsOpen(!datePickerIsOpen)}
      maxDate={maxDate}
    />
  );
};
