import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { CustomDatePicker } from "./custom-date-picker";
import { useState } from "react";

type FormDatepickerProps<FormType extends FieldValues> = {
  className?: string;
  isSmall?: boolean;
  initialDate?: Date;
} & UseControllerProps<FormType>;

export const FormDatepicker = <FormType extends FieldValues>({
  name,
  control,
  rules,
  initialDate = new Date(),
}: FormDatepickerProps<FormType>) => {
  const [date, setDate] = useState<Date>(initialDate);
  const { field } = useController({
    name,
    control,
    rules,
  });

  const onDateChange = (date: Date | null) => {
    setDate((prevDate) => (date ? date : prevDate));
    field.onChange(date);
  };

  return (
    <CustomDatePicker
      isSmall
      isForm
      selectedDate={date}
      variant="secondary"
      onChange={(date) => onDateChange(date)}
    />
  );
};
