import React from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { PageTemplateClient } from "../../components/page-template-client";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { pages } from "../../util/pages";
import { NavigateToHome } from "../../components/navigate-to-home";

export const NotRecognized = () => {
  const { dispatch } = useAppData();
  const navigate = useNavigate();

  const tryAgainHandler = (e: React.PointerEvent) => {
    e.preventDefault();
    if (!navigator.mediaDevices) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Face recognition requires https",
        },
      });
      return;
    }
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(pages.RECOGNIZE);
  };

  const changePageHandler = (page: string) => {
    dispatch({
      type: actions.SET_ERROR,
      payload: null,
    });
    navigate(page);
  };

  return (
    <PageTemplateClient>
      <div className="flex flex-col">
        <div className="flex flex-col gap-8 w-[55vw]">
          <NavigateToHome />
          <h1>We couldn't identify you </h1>
          <p>
            We weren't able to identify you yet. <br />
            Have you checked in with us before?
          </p>
        </div>

        <div className={"flex flex-col gap-8 items-center mt-12 w-full"}>
          <Button
            variant={"primary"}
            onPointerDown={() => changePageHandler(pages.NEW_FACE_FORM)}
          >
            First time here
          </Button>
          <Button variant={"secondary"} onPointerDown={tryAgainHandler}>
            Try again
          </Button>
        </div>
      </div>
    </PageTemplateClient>
  );
};
