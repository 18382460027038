import { twMerge } from "tailwind-merge";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Label } from "./label";

type InputProps<FormType extends FieldValues> = {
  label: string;
  type?: string;
  inputClassName?: string;
  isSmall?: boolean;
  placeholder?: string;
  required?: boolean;
} & UseControllerProps<FormType>;

export const Input = <FormType extends FieldValues>({
  label,
  type = "text",
  inputClassName,
  isSmall = false,
  placeholder,
  required = false,
  name,
  control,
  rules,
}: InputProps<FormType>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  return (
    <div>
      <Label htmlFor={name}>{label}</Label>
      <input
        {...field}
        className={twMerge(
          `h-12 block w-full pb-2 bg-clip-padding border-b border-solid border-grey-grey90 transition ease-in-out m-0 focus:border-b-2 focus:outline-none placeholder-grey-grey85
          ${isSmall ? "bg-white font-light pl-2" : "bg-primary-baseGreen"}`,
          inputClassName
        )}
        id={name}
        autoComplete="off"
        type={type}
        placeholder={placeholder}
        required={required}
      />
      <p className="text-red text-base fixed">{fieldState.error?.message}</p>
    </div>
  );
};
