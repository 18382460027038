import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components/button";
import { Countdown } from "../../components/countdown";
import { Modal } from "../../components/modal";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { ReactComponent as IconLoading } from "../../svg/loading.svg";
import { pages } from "../../util/pages";
import { responseCodesVideo } from "../../types/responseCodesVideo";
import {
  useClientExpectedCheckouts,
  useRecognizeFace,
} from "../../api/__generated__/hooks";
import { CameraTipsText } from "../../components/camera-tips-text";
import { CameraConsentText } from "../../components/camera-consent-text";
import { ReactComponent as CameraColored } from "../../svg/camera_colored.svg";
const CameraFrame = () => (
  <div className={" w-[87%] absolute left-10 right-16 top-16 bottom-16 z-10"}>
    <div
      className={
        "absolute border-white border-t-4 border-l-4 w-24 h-24 top-0 left-0"
      }
    />
    <div
      className={
        "absolute border-white border-t-4 border-r-4 w-24 h-24 top-0 right-0"
      }
    />
    <div
      className={
        "absolute border-white border-b-4 border-l-4 w-24 h-24 bottom-0 left-0"
      }
    />
    <div
      className={
        "absolute border-white border-b-4 border-r-4 w-24 h-24 bottom-0 right-0"
      }
    />
  </div>
);

const Flash = () => (
  <div className={"absolute inset-0 bg-white z-10 opacity-0 animate-flash"} />
);

export const VideoStreamCapture = () => {
  const { dispatch } = useAppData();
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [isTakingPicture, setIsTakingPicture] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isSlow, setIsSlow] = useState(false);
  const videoElement = useRef<HTMLVideoElement>(null);
  const canvasElement = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shouldShowTips = searchParams.get("tips") === "true";
  const {
    data: expectedCheckouts,
    isError: expectedCheckoutsIsError,
    error: expectedCheckoutsError,
  } = useClientExpectedCheckouts();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "user",
        },
      })
      .then((stream) => {
        if (videoElement.current) {
          videoElement.current.srcObject = stream;
        }
        setIsCameraReady(true);
      })
      .catch((error) => {
        console.error("Please allow this website to use your webcam.", error);
      });
  }, []);

  useEffect(() => {
    // We need the expected checkouts to determine if a person should see a check in or check out action on the next page.
    if (expectedCheckouts) {
      dispatch({
        type: actions.SET_EXPECTED_CHECKOUTS,
        payload: expectedCheckouts,
      });
    } else if (expectedCheckoutsIsError) {
      console.error("Error fetching expectedCheckouts", expectedCheckoutsError);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message:
            "Current expected check out list could not be retrieved, check in or out using the manual flow.",
        },
      });
      navigate(pages.HOME);
    }
  }, [
    dispatch,
    expectedCheckouts,
    expectedCheckoutsError,
    expectedCheckoutsIsError,
    navigate,
  ]);

  // Copies the current video frame to a hidden canvas element to get an image data URL
  const getImageDataFromVideo = () => {
    if (canvasElement.current && videoElement.current) {
      canvasElement.current.width = videoElement.current.videoWidth;
      canvasElement.current.height = videoElement.current.videoHeight;

      const ctx = canvasElement.current.getContext("2d");
      if (ctx) {
        ctx.drawImage(
          videoElement.current,
          0,
          0,
          videoElement.current.videoWidth,
          videoElement.current.videoHeight
        );
      }

      const imageDataURL = canvasElement.current.toDataURL();

      if (imageDataURL === "data:,") {
        throw new Error("Failed to retrieve image from the camera");
      }

      return {
        image: imageDataURL.replace(/^data:image\/(png);base64,/, ""),
        imageWithMeta: imageDataURL,
      };
    }
  };

  const { mutate: mutateRecognizeFace } = useRecognizeFace({
    mutation: {
      onSuccess: (response) => {
        dispatch({
          type: actions.SET_RECOGNIZED_VISITOR,
          payload: response,
        });
        navigate(pages.FACE_RECOGNIZED);
      },
      onError: (error) => {
        switch (error.response.data.code) {
          // No face was detected in the image
          case responseCodesVideo.NO_FACE_IN_IMAGE:
            navigate(pages.FACE_NOT_DETECTED);
            break;
          // A face was detected, but not recongized and a face was recognized but no visitor data associated with it
          case responseCodesVideo.FACE_NOT_RECOGNIZED:
          case responseCodesVideo.NO_VISITOR_FOR_FACEID:
            navigate(pages.FACE_NOT_RECOGNIZED);
            break;
          default:
            console.error(
              "Something went wrong while recognizing you.",
              error.response.data.code
            );
            dispatch({
              type: actions.SET_ERROR,
              payload: {
                code: "application_error",
                message:
                  "Something went wrong while recognizing you. Please try again or use the manual form.",
              },
            });
            navigate(pages.FACE_NOT_RECOGNIZED);
            break;
        }
      },
    },
  });

  const takePicture = useCallback(async () => {
    let imageData;
    try {
      imageData = getImageDataFromVideo();
      if (!imageData) {
        throw Error("Image data is undefined");
      }
    } catch (error) {
      // Image could not be taken from the stream, this gives the user a way to escape this page
      // and go through manual flow
      console.error("Failed to retrieve image from the camera");
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message:
            "Failed to retrieve image from the camera. Please try again or use the manual form.",
        },
      });
      navigate(pages.FACE_NOT_RECOGNIZED);
      return;
    }

    dispatch({
      type: actions.SET_CAPTURED_IMAGE,
      payload: imageData,
    });

    setIsFlashing(true);
    if (videoElement.current) {
      videoElement.current.pause();
    }
    mutateRecognizeFace({ imageData: imageData.image });

    setTimeout(() => setIsSlow(true), 3000);
  }, [dispatch, mutateRecognizeFace, navigate]);

  const cancelHandler = () => {
    // User has declined to take a picture, so we remove any picture taken before
    dispatch({
      type: actions.SET_CAPTURED_IMAGE,
      payload: null,
    });
    navigate(pages.HOME);
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="absolute inset-0 flex justify-center items-center">
        {isTakingPicture && (
          <Countdown
            onFinished={takePicture}
            initialTicks={0}
            tickDuration={0}
          />
        )}
        {isSlow && <IconLoading className="w-40" />}
      </div>

      <div
        className={`flex items-center justify-center w-full h-full transition-all duration-500 ${
          isCameraReady ? "opacity-100" : "opacity-0"
        } ${isSlow ? "blur-lg" : "blur-none"}`}
      >
        {isFlashing && <Flash />}
        {!isFlashing && <CameraFrame />}
        <video
          ref={videoElement}
          controls={false}
          autoPlay
          className={"w-full h-full object-cover -scale-x-100"}
        />
        <canvas ref={canvasElement} className={"hidden"} />
      </div>

      <Modal
        show={!isTakingPicture}
        className={"w-4/5 flex flex-col justify-between gap-6 select-none"}
        tapOutsideShouldClose={false}
        onClose={cancelHandler}
      >
        <h3 className={"text-3xl mb-0"}>
          Get ready to capture that perfect photo!
          <CameraColored className="inline -mt-2 ml-1" />
        </h3>
        {shouldShowTips ? <CameraTipsText /> : <CameraConsentText />}

        <div className="flex flex-col items-center gap-6">
          <Button
            variant={"primary"}
            onPointerDown={() => setIsTakingPicture(true)}
            disabled={!isCameraReady}
          >
            {isCameraReady ? "Take my picture" : "Waiting for camera..."}
          </Button>
          <Button variant={"secondary"} onPointerDown={() => cancelHandler()}>
            {shouldShowTips ? "Never mind" : "I don't want this"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
