import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ClientCheckinNewMutationRequest, ClientCheckinNewMutationResponse } from "../types/ClientCheckinNew";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ClientCheckinNewClient = typeof client<ClientCheckinNewMutationResponse, never, ClientCheckinNewMutationRequest>;
type ClientCheckinNew = {
    data: ClientCheckinNewMutationResponse;
    error: never;
    request: ClientCheckinNewMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ClientCheckinNewMutationResponse;
    client: {
        parameters: Partial<Parameters<ClientCheckinNewClient>[0]>;
        return: Awaited<ReturnType<ClientCheckinNewClient>>;
    };
};
/**
 * @description Check in a user without an expected visit. This user will need to supply visitor and visit data.
 * @link /vat-client/checkin-new
 */
export function useClientCheckinNew(options: {
    mutation?: UseMutationOptions<ClientCheckinNew["response"], ClientCheckinNew["error"], ClientCheckinNew["request"]>;
    client?: ClientCheckinNew["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ClientCheckinNew["data"], ClientCheckinNew["error"], ClientCheckinNew["request"]>({
                method: "post",
                url: `/vat-client/checkin-new`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}