import { Button } from "./button";
import { Modal } from "./modal";
import { Client } from "../api/__generated__";

type DeleteClientModalProps = {
  modalClient: Client;
  show: boolean;
  onClose: () => void;
  deleteClient: () => void;
};
export const DeleteClientModal = ({
  modalClient,
  show,
  onClose,
  deleteClient,
}: DeleteClientModalProps) => {
  return (
    <Modal
      isManagementModal
      className={"w-1/4 flex flex-col gap-4"}
      onClose={onClose}
      show={show}
    >
      <h5 className="mb-0">Delete device</h5>
      <div>
        <p>
          Are you sure you want to delete device{" "}
          <span className="client-name">{modalClient.name}</span>
        </p>
        <p>
          To activate this device again, it will have to be recreated and
          activated again by scanning the QR code.
        </p>
      </div>
      <div className="flex justify-end gap-2">
        <Button isSmall variant={"secondary"} onPointerDown={() => onClose()}>
          Cancel
        </Button>
        <Button
          isSmall
          variant={"primary"}
          onPointerDown={() => deleteClient()}
        >
          Delete device
        </Button>
      </div>
    </Modal>
  );
};
