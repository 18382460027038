import { useEffect } from "react";
import { useManagementRetrieveOrganizers } from "../api/__generated__";
import { useAppData } from "./use-app-data";
import { actions } from "../types/actions";

export const useOrganizers = () => {
  const { data, error } = useManagementRetrieveOrganizers({
    query: {
      staleTime: 600000, // Override to 10 minutes
    },
  });

  const { dispatch } = useAppData();

  useEffect(() => {
    if (error) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Organizers could could not be retrieved",
        },
      });
    }
  }, [dispatch, data, error]);

  return { data };
};
