import { validationRules } from "../util/form-validation";
import { Button } from "./button";
import { Input } from "./input";
import { Modal } from "./modal";
import { FormSearchableDropdown } from "./form-searchable-dropdown";
import { useAppData } from "../hooks/use-app-data";
import { SubmitHandler, useForm } from "react-hook-form";
import { RegisterNewClientForm } from "../types/registerNewClientForm";
import { useGetVatManagementClientsLocations } from "../api/__generated__";
import { useEffect } from "react";
import { actions } from "../types/actions";

type AddClientModalProps = {
  onClose: () => void;
  submitForm: SubmitHandler<RegisterNewClientForm>;
};

export const AddClientModal = ({
  onClose,
  submitForm,
}: AddClientModalProps) => {
  const { dispatch } = useAppData();
  const { data: clientLocations, error: locationsError } =
    useGetVatManagementClientsLocations();
  const { handleSubmit, control, reset } = useForm<RegisterNewClientForm>({
    defaultValues: {
      name: "",
      location: "",
    },
  });

  useEffect(() => {
    if (locationsError) {
      console.error(locationsError);
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          code: "application_error",
          message: "Could not retrieve locations.",
        },
      });
    }
  }, [dispatch, locationsError]);

  const mappedLocations = clientLocations?.map((location) => ({
    label: location.replace(/_/g, " ").toLowerCase(),
    value: location,
  }));

  const cancelRegister = () => {
    reset({
      name: "",
      location: "",
    });
    onClose();
  };

  return (
    <Modal
      isManagementModal
      className={"w-1/4 flex flex-col gap-4"}
      onClose={cancelRegister}
      show
    >
      <h5 className={"mb-0 "}>Register new device</h5>
      <form onSubmit={handleSubmit(submitForm)} className="flex flex-col gap-6">
        <Input
          label={"Name"}
          name={"name"}
          placeholder="Enter name"
          isSmall
          control={control}
          rules={validationRules.registerClientName}
        />
        {clientLocations && (
          <FormSearchableDropdown<RegisterNewClientForm>
            label="Location"
            name={"location"}
            control={control}
            rules={validationRules.locationSelection}
            placeholder={"Select location"}
            options={mappedLocations}
          />
        )}
        <div className="flex justify-end gap-2 pt-2">
          <Button
            isSmall
            variant={"secondary"}
            onPointerDown={() => cancelRegister()}
          >
            Cancel
          </Button>
          <Button isSmall variant={"primary"}>
            Register
          </Button>
        </div>
      </form>
    </Modal>
  );
};
