import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../../components/button";
import { Loading } from "../../components/loading";
import { Modal } from "../../components/modal";
import { NoAccess } from "../../components/no-access";
import { PageTemplateManagement } from "../../components/page-template-management";
import { Table } from "../../components/table";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { userHasAccess } from "../../util/check-user-access";
import {
  UpdateRoleOfUserMutationRequest,
  User,
  UserRole,
  userRole,
} from "../../api/__generated__/types";
import {
  useGetUsersManagement,
  useGetAllRoles,
  useUpdateRoleOfUser,
} from "../../api/__generated__/hooks/";

export const UserManagement = () => {
  const { state, dispatch } = useAppData();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalUser, setModalUser] = useState<User | null>(null);
  const [selectedRole, setSelectedRole] = useState<UserRole>("NONE");
  const { data: apiUsers, error: usersError } =
    useGetUsersManagement(undefined);
  const { data: apiRoles, error: rolesError } = useGetAllRoles();
  const updateRoleMutation = useUpdateRoleOfUser(modalUser?.id || "");

  useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (userHasAccess(state.profile, [userRole.ADMIN])) {
      if (apiUsers) {
        dispatch({ type: actions.SET_USERS, payload: apiUsers });
      }

      if (apiRoles) {
        dispatch({ type: actions.SET_ROLES, payload: apiRoles });
      }
      if (usersError || rolesError) {
        console.error("failed to retrieve data", usersError || rolesError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: "Current data could not be retrieved",
          },
        });
      }
    }
    setIsLoading(false);
  }, [apiRoles, apiUsers, dispatch, rolesError, state.profile, usersError]);

  const openModal = (user: User) => {
    setSelectedRole(user.role);
    setModalUser(user);
    setShowModal(true);
  };

  const submitChangeRole = async () => {
    if (!modalUser) {
      console.error("ModalUser is null or undefined");
      return;
    }
    setIsLoading(true);
    setShowModal(false);

    updateRoleMutation.mutate(
      { role: selectedRole } as UpdateRoleOfUserMutationRequest,
      {
        onSuccess: () => {
          if (state.users) {
            modalUser.role = selectedRole;
            const indexUser = state.users.indexOf(modalUser);
            state.users[indexUser] = modalUser;
            dispatch({ type: actions.SET_USERS, payload: state.users });
          }
        },
        onError: (error) => {
          console.error("The role can't be updated", error);
          dispatch({
            type: actions.SET_ERROR,
            payload: {
              code: "application_error",
              message: "The role can not be updated",
            },
          });
        },
        onSettled: () => {
          setIsLoading(false);
        },
      }
    );
  };

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);
  const handleSelectedRole = (event: { target: { value: string } }) => {
    const newRole = event.target.value as UserRole;
    setSelectedRole(newRole);
  };

  if (!userHasAccess(state.profile, [userRole.ADMIN])) {
    return state.profile ? <NoAccess /> : <Loading />;
  }

  return (
    <PageTemplateManagement error={state.error}>
      <div className={"flex flex-row items-center mb-6"}>
        <h4>Manage your users</h4>
      </div>

      {isLoading && <div>Loading...</div>}
      {state.users && state.users.length > 0 && !isLoading && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.users.map((user, i) => (
              <tr key={i}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td className={"capitalize"}>
                  {user.role.replace(/_/g, " ").toLowerCase()}
                </td>
                <td>
                  <Button
                    isSmall
                    variant={"link"}
                    onPointerDown={() => openModal(user)}
                    className={
                      "text-primary-blue font-semibold hover:text-primary-darkBlue"
                    }
                  >
                    Change role
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {state.users && state.users.length === 0 && <div>No users found</div>}

      <Modal
        isManagementModal
        className={"flex flex-col gap-4"}
        onClose={onCloseModal}
        show={showModal}
      >
        <h5 className={"mb-0"}>Change role</h5>
        <form>
          <label>{modalUser?.name}</label>
          <Table className={"mt-2"}>
            <tbody>
              {state.roles &&
                state.roles.map((role, i) => (
                  <tr key={i} className="border-none">
                    <td className="p-2">
                      <input
                        id={`role-${i}`}
                        type="radio"
                        value={role.roleName}
                        name="role"
                        checked={role.roleName === selectedRole}
                        onChange={handleSelectedRole}
                        className={"accent-primary-blue"}
                      />
                    </td>
                    <td className="capitalize p-2">
                      <label htmlFor={`role-${i}`}>
                        {role.roleName.replace(/_/g, " ").toLowerCase()}
                      </label>
                    </td>
                    <td className="pl-4 pt-2 italic">
                      <label htmlFor={`role-${i}`}>{role.description}</label>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </form>
        <div className="flex justify-end gap-2">
          <Button
            isSmall
            variant={"secondary"}
            onPointerDown={() => onCloseModal()}
          >
            Cancel
          </Button>
          <Button
            isSmall
            variant={"primary"}
            onPointerDown={() => submitChangeRole()}
          >
            Change role
          </Button>
        </div>
      </Modal>
    </PageTemplateManagement>
  );
};
