import { useCallback, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../svg/close_icon.svg";
import { actions } from "../types/actions";
import { useAppData } from "../hooks/use-app-data";

interface ErrorProps {
  error: { message: string } | null;
}

export const ErrorHandling = ({ error }: ErrorProps) => {
  const { dispatch } = useAppData();

  const clearError = useCallback(() => {
    dispatch({ type: actions.SET_ERROR, payload: null });
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        clearError();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [error, clearError]);

  if (!error) return null;

  return (
    <div className="absolute left-0 top-0 right-0 text-center p-2 bg-red text-white">
      {error.message}
      <button onPointerDown={clearError} className="absolute right-2 top-2">
        <CloseIcon fill="white" />
      </button>
    </div>
  );
};
