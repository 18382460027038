import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button";
import { Loading } from "../../components/loading";
import { NoAccess } from "../../components/no-access";
import { PageTemplateManagement } from "../../components/page-template-management";
import { Table } from "../../components/table";
import { actions } from "../../types/actions";
import { useAppData } from "../../hooks/use-app-data";
import { userHasAccess } from "../../util/check-user-access";
import { pages } from "../../util/pages";
import { Client, userRole } from "../../api/__generated__/types";
import {
  useManagementDeleteClient,
  useManagementGenerateClientUrl,
  useManagementGetClients,
} from "../../api/__generated__/hooks/";
import { SubmitHandler } from "react-hook-form";
import { DeleteClientModal } from "../../components/modal-delete-client";
import { AddClientModal } from "../../components/modal-add-client";
import { RegisterNewClientForm } from "../../types/registerNewClientForm";

export const DeviceManagement = () => {
  const { state, dispatch } = useAppData();
  const [showModalRegisterClient, setShowModalRegisterClient] = useState(false);
  const [showModalDeleteClient, setShowModalDeleteClient] = useState(false);
  const [modalClient, setModalClient] = useState<Client | null>(null);
  const navigate = useNavigate();
  useMsalAuthentication(InteractionType.Redirect);

  const {
    data: clients,
    error: fetchClientsError,
    refetch: refetchClients,
    isPending: fetchClientsIsPending,
  } = useManagementGetClients();

  useEffect(() => {
    if (userHasAccess(state.profile, [userRole.ADMIN])) {
      if (clients) {
        dispatch({ type: actions.SET_CLIENTS, payload: clients });
      }

      if (fetchClientsError) {
        console.error("Devices could not be retrieved", fetchClientsError);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: `The devices could not be retrieved.`,
          },
        });
      }
    }
  }, [clients, fetchClientsError, dispatch, state.profile]);

  const openModalDeleteDevice = (
    client: React.SetStateAction<Client | null>
  ) => {
    if (!client) return;
    setShowModalDeleteClient(true);
    setModalClient(client);
  };

  const { mutate: generateClientUrl } = useManagementGenerateClientUrl({
    mutation: {
      onSuccess: (qrData) => {
        refetchClients();
        // Set qr data to be used for rendering qr code
        dispatch({
          type: actions.SET_QR_DATA,
          payload: JSON.stringify(qrData),
        });
        // Redirect to qr code page
        navigate(pages.MANAGEMENT_DEVICES_REGISTER);
      },
      onError: (error) => {
        console.error("QR code could not be generated", error);
        dispatch({
          type: actions.SET_ERROR,
          payload: {
            code: "application_error",
            message: `QR code could not be generated, please try again.`,
          },
        });
      },
      onSettled: () => {
        setShowModalRegisterClient(false);
      },
    },
  });

  const deleteClientMutation = useManagementDeleteClient(
    modalClient?.sessionId || "",
    {
      mutation: {
        onSuccess: () => {
          setShowModalDeleteClient(false);
          refetchClients();
        },
        onError: (error) => {
          console.error("Client could not be deleted", error);
          dispatch({
            type: actions.SET_ERROR,
            payload: {
              code: "application_error",
              message: `Client could not be deleted.`,
            },
          });
        },
      },
    }
  );

  if (!userHasAccess(state.profile, [userRole.ADMIN])) {
    return state.profile ? <NoAccess /> : <Loading />;
  }

  const deleteClient = async () => {
    if (!modalClient || !modalClient.sessionId) return;
    deleteClientMutation.mutate({} as never);
  };

  const submitForm: SubmitHandler<RegisterNewClientForm> = (
    registerDeviceData: RegisterNewClientForm
  ) => {
    generateClientUrl(registerDeviceData);
  };

  return (
    <PageTemplateManagement error={state.error}>
      <div className={"flex flex-row items-center mb-6"}>
        <h4>Manage your devices</h4>

        <Button
          isSmall
          variant={"primary"}
          onPointerDown={() => setShowModalRegisterClient(true)}
          className={"ml-6"}
        >
          Register device
        </Button>
      </div>

      {fetchClientsIsPending && <div className="ml-6">Loading...</div>}
      {state.clients && state.clients.length > 0 && !fetchClientsIsPending ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Last Used</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.clients &&
              state.clients.length > 0 &&
              state.clients.map((client, i) => (
                <tr key={i}>
                  <td>{client.name}</td>
                  <td className="capitalize">
                    {client.location.replace(/_/g, " ").toLowerCase()}
                  </td>
                  <td>
                    {client.lastUsed
                      ? new Intl.DateTimeFormat(undefined, {
                          dateStyle: "long",
                          timeStyle: "short",
                        }).format(new Date(client.lastUsed))
                      : "Date not available"}
                  </td>
                  <td>
                    <Button
                      isSmall
                      variant={"link"}
                      onPointerDown={() => openModalDeleteDevice(client)}
                      className={"text-red hover:text-red-dark font-semibold"}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div>No devices found</div>
      )}
      {showModalRegisterClient && (
        <AddClientModal
          onClose={() => setShowModalRegisterClient(false)}
          submitForm={submitForm}
        />
      )}
      {modalClient && (
        <DeleteClientModal
          modalClient={modalClient}
          show={showModalDeleteClient}
          onClose={() => setShowModalDeleteClient(false)}
          deleteClient={() => deleteClient()}
        />
      )}
    </PageTemplateManagement>
  );
};
