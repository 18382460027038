import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonVariants } from "../types/buttonVariants";

type ButtonProps = {
  children: React.ReactNode;
  variant?: ButtonVariants;
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  isSmall?: boolean;
  onPointerDown?: (event: React.PointerEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.PointerEvent<HTMLButtonElement>) => void;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      className,
      isLoading,
      children,
      isSmall = false,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const small = "w-32 py-2 text-sm";
    const big = "w-full py-4 text-2xl";

    switch (variant) {
      case "primary":
        return (
          <button
            className={twMerge(
              `bg-primary-blue text-white rounded-full relative overflow-hidden transition 
               disabled:bg-secondary-blue 
               ${isSmall ? `hover:bg-primary-darkBlue ${small}` : big}`,
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
            {isLoading && (
              <div className="flex flex-col absolute top-0 w-full">
                <div className="relative w-full rounded">
                  <div className="h-1 w-full rounded loading-animation"></div>
                </div>
              </div>
            )}
          </button>
        );
      case "secondary":
        return (
          <button
            className={twMerge(
              `border-grey-grey30 bg-grey-grey10 border-2 rounded-full 
              ${isSmall ? `hover:border-grey-grey85 ${small}` : big}`,
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </button>
        );
      case "link":
        return (
          <button
            className={twMerge(
              ` ${isSmall ? "text-base" : "text-xl"}`,
              className
            )}
            ref={ref}
            {...props}
          >
            {children}
          </button>
        );
      default:
        throw new Error("Variant prop for Button is required.");
    }
  }
);
