import client from "../../client";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ManagementGetVisitsQueryResponse, ManagementGetVisitsQueryParams } from "../types/ManagementGetVisits";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ManagementGetVisitsClient = typeof client<ManagementGetVisitsQueryResponse, never, never>;
type ManagementGetVisits = {
    data: ManagementGetVisitsQueryResponse;
    error: never;
    request: never;
    pathParams: never;
    queryParams: ManagementGetVisitsQueryParams;
    headerParams: never;
    response: ManagementGetVisitsQueryResponse;
    client: {
        parameters: Partial<Parameters<ManagementGetVisitsClient>[0]>;
        return: Awaited<ReturnType<ManagementGetVisitsClient>>;
    };
};
export const managementGetVisitsQueryKey = (params: ManagementGetVisits["queryParams"]) => [{ url: "/vat-management/visits" }, ...(params ? [params] : [])] as const;
export type ManagementGetVisitsQueryKey = ReturnType<typeof managementGetVisitsQueryKey>;
export function managementGetVisitsQueryOptions(params: ManagementGetVisits["queryParams"], options: ManagementGetVisits["client"]["parameters"] = {}) {
    const queryKey = managementGetVisitsQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ManagementGetVisits["data"], ManagementGetVisits["error"]>({
                method: "get",
                url: `/vat-management/visits`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get a list of visits with a from-to day range
 * @link /vat-management/visits
 */
export function useManagementGetVisits<TData = ManagementGetVisits["response"], TQueryData = ManagementGetVisits["response"], TQueryKey extends QueryKey = ManagementGetVisitsQueryKey>(params: ManagementGetVisits["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ManagementGetVisits["response"], ManagementGetVisits["error"], TData, TQueryData, TQueryKey>>;
    client?: ManagementGetVisits["client"]["parameters"];
} = {}): UseQueryResult<TData, ManagementGetVisits["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? managementGetVisitsQueryKey(params);
    const query = useQuery({
        ...managementGetVisitsQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ManagementGetVisits["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const managementGetVisitsSuspenseQueryKey = (params: ManagementGetVisits["queryParams"]) => [{ url: "/vat-management/visits" }, ...(params ? [params] : [])] as const;
export type ManagementGetVisitsSuspenseQueryKey = ReturnType<typeof managementGetVisitsSuspenseQueryKey>;
export function managementGetVisitsSuspenseQueryOptions(params: ManagementGetVisits["queryParams"], options: ManagementGetVisits["client"]["parameters"] = {}) {
    const queryKey = managementGetVisitsSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ManagementGetVisits["data"], ManagementGetVisits["error"]>({
                method: "get",
                url: `/vat-management/visits`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @description Get a list of visits with a from-to day range
 * @link /vat-management/visits
 */
export function useManagementGetVisitsSuspense<TData = ManagementGetVisits["response"], TQueryKey extends QueryKey = ManagementGetVisitsSuspenseQueryKey>(params: ManagementGetVisits["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ManagementGetVisits["response"], ManagementGetVisits["error"], TData, TQueryKey>>;
    client?: ManagementGetVisits["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ManagementGetVisits["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? managementGetVisitsSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...managementGetVisitsSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ManagementGetVisits["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}