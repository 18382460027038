import { useNavigate } from "react-router";
import { PageTemplateClient } from "../../components/page-template-client";
import { ProgressBar } from "../../components/progress-bar";
import { useAppData } from "../../hooks/use-app-data";
import { ReactComponent as IconVisitorBadge } from "../../svg/io_visitor_badge.svg";
import { pages } from "../../util/pages";
import { ReactComponent as CheckMark } from "../../svg/check_mark_green.svg";
import { useSearchParams } from "react-router-dom";
import { NavigateToHome } from "../../components/navigate-to-home";

export const ConfirmationPage = () => {
  const { state } = useAppData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const checkedInpage = searchParams.get("checkedIn") === "true";

  const checkedInText = {
    title: "Successful check in",
    subtitleLineOne: "Don't forget to check out afterwards!",
    subtitleLineTwo: "",
    badgeText: "Please grab a badge",
  };

  const checkedOutText = {
    title: "Successful check out",
    subtitleLineOne: "We hope you had a nice visit.",
    subtitleLineTwo: "See you next time!",
    badgeText: "Please return your badge",
  };

  const content = checkedInpage ? checkedInText : checkedOutText;

  return (
    <PageTemplateClient>
      <div className="flex flex-col gap-12">
        <div>
          <h1 data-testid="page-title" className={"mb-8"}>
            <CheckMark className="inline-flex mr-4" />
            {content.title}
          </h1>
          <p>
            {content.subtitleLineOne} <br />
            {content.subtitleLineTwo}
          </p>
        </div>
        <div className="mb-20">
          <div className="flex flex-row items-center mb-12">
            <div className={"w-12"}>
              <IconVisitorBadge />
            </div>
            <h3 className={"ml-8 mt-4"}>{content.badgeText}</h3>
          </div>
          <p>
            Name: <br />
            {checkedInpage
              ? state.clientCheckInNew?.name
              : state.checkedOutVisitor?.name}
          </p>
        </div>
        <NavigateToHome />
      </div>

      <ProgressBar onFinished={() => navigate(pages.HOME)} />
    </PageTemplateClient>
  );
};
