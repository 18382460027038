import client from "../../client";
import { useMutation } from "@tanstack/react-query";
import type { ManagementCheckinExpectedMutationRequest, ManagementCheckinExpectedMutationResponse } from "../types/ManagementCheckinExpected";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ManagementCheckinExpectedClient = typeof client<ManagementCheckinExpectedMutationResponse, never, ManagementCheckinExpectedMutationRequest>;
type ManagementCheckinExpected = {
    data: ManagementCheckinExpectedMutationResponse;
    error: never;
    request: ManagementCheckinExpectedMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ManagementCheckinExpectedMutationResponse;
    client: {
        parameters: Partial<Parameters<ManagementCheckinExpectedClient>[0]>;
        return: Awaited<ReturnType<ManagementCheckinExpectedClient>>;
    };
};
/**
 * @description Check in with an expected visit. This visit can be retieved using the vat-management/visits call
 * @link /vat-management/checkin-expected
 */
export function useManagementCheckinExpected(options: {
    mutation?: UseMutationOptions<ManagementCheckinExpected["response"], ManagementCheckinExpected["error"], ManagementCheckinExpected["request"]>;
    client?: ManagementCheckinExpected["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    return useMutation({
        mutationFn: async (data) => {
            const res = await client<ManagementCheckinExpected["data"], ManagementCheckinExpected["error"], ManagementCheckinExpected["request"]>({
                method: "post",
                url: `/vat-management/checkin-expected`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions
    });
}