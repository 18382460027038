import React from "react";
import { ReactComponent as IOLogo } from "../svg/iO_logo.svg";
import { NavigateToHome } from "./navigate-to-home";

type PageTemplateClientProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  navigateToHome?: boolean;
};

export const PageTemplateClient = ({
  children,
  navigateToHome = false,
  footer,
}: PageTemplateClientProps) => (
  <div className="flex flex-col h-full w-full select-none clientPageTemplate">
    <div className="flex justify-end p-10">
      <IOLogo />
    </div>
    <main
      role="main"
      className="flex items-start flex-col flex-grow w-full px-10 justify-around"
    >
      <div className="overflow-auto flex flex-col gap-8">
        {navigateToHome && <NavigateToHome />}
        {children}
      </div>
    </main>
    {footer && (
      <footer className="flex flex-col mt-auto py-14 px-3 pl-10 items-start ">
        {footer}
      </footer>
    )}
  </div>
);
